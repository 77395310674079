.td_actions{
  flex: 1;
  display: flex;
}
.btn_action{
  padding-right: 10px;
}

.tableFooter {
  background-color: #f1f1f1;
  padding: 8px 0px;
  width: 100%;
  font-weight: 500;
  text-align: left;
  font-size: 16px;
  color: #2c3e50;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  border: none;
  padding: 7px 14px;
  border-radius: 10px;
  cursor: pointer;
  margin-right: 4px;
  margin-left: 4px;
}

.activeButton {
  color: white;
  background: #185adb;
}

.inactiveButton {
  color: #2c3e50;
  background: #f9f9f9;
}

.tools{
  display: flex;
  flex-direction: row;
}

select {
  letter-spacing: 1.2px;
  font-family: 'Roboto';
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  /* font-family: inherit;
  font-size: inherit; */
  cursor: inherit;
  line-height: inherit;
  z-index: 1;
  outline: none;
}
select::-ms-expand {
  display: none;
}

.select {
  font-family: 'Roboto';

  width: 100%;
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  position: relative;
  min-width: 15ch;
  border-bottom: 1px solid #d2d2d2;
  padding: 0.25em 0.5em;
  cursor: pointer;
  line-height: 1.1;
  background-color: 'transparent';
}
.select select, .select::after {
  grid-area: select;
}


select:focus + .focus {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border: 2px solid red;
  border-radius: inherit;
}

.select--disabled {
  cursor: not-allowed;
  background-color: #eee;
  background-image: linear-gradient(to top, #ddd, #eee 33%);
}

.pagination_bar {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}
.pagination_container {
  display: flex;
  list-style-type: none;
}
.pagination_container .pagination_item {
  padding: 0 12px;
  height: 32px;
  text-align: center;
  margin: auto 4px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 16px;
  line-height: 1.43;
  font-size: 13px;
  min-width: 32px;
}
.pagination_container .pagination_item.dots:hover {
  background-color: transparent;
  cursor: default;
}
.pagination_container .pagination_item:hover {
    color: rgba(0, 0, 0, 0.87);
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}
.pagination_container .pagination_item .selected {
  background-color: rgba(0, 0, 0, 0.08);
}
.pagination_container .pagination_item .arrow::before {
  position: relative;
  content: '';
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  border-right: 0.12em solid rgba(0, 0, 0, 0.87);
  border-top: 0.12em solid rgba(0, 0, 0, 0.87);
}
.pagination_container .pagination_item .arrow.left {
  transform: rotate(-135deg) translate(-50%);
}
.pagination_container .pagination_item .arrow.right {
  transform: rotate(45deg);
}
.pagination_container .pagination_item.disabled {
  pointer-events: none;
}
.pagination_container .pagination_item.disabled .arrow::before {
  border-right: 0.12em solid rgba(0, 0, 0, 0.43);
  border-top: 0.12em solid rgba(0, 0, 0, 0.43);
}
.pagination_container .pagination_item.disabled:hover {
  background-color: transparent;
  cursor: default;
}
