nav span.menu__item-name {
  font-size: 16px;
  padding: 10px;
  margin: 10px;
}

.esperando {
  font-size: 22px;
  color: #f29b72;
}

.suavez {
  font-size: 22px;
  color: #2ecc71;
}

.muilabel label {
  width: 100%;
  text-align: left;
  margin-top: 10px;
  margin-left: auto;
  display: flex;
}

.muilabel label span {
  color: rgb(185, 21, 54);
}

.nome_farmacia {
  padding-top: 20px;
}

.title_farmacia {
  border-radius: 5px 5px 0px 0px;
  background: #355dff;
  color: #fff;
  font-size: 20px;
}

.sound_on_off {
  float: right;
  margin-right: 8px !important;
  margin-top: -64px !important;
}

.sound_icon {
  margin-top: 8px;
}

/* // mudar todos os #3f51b5 pra essa cor #2579ec, principalmente // */

.MuiButton-containedPrimary {
  background: #2579ec;
}

.MuiRadio-colorPrimary.Mui-checked {
  color: #2579ec;
}

.MuiLinearProgress-barColorPrimary {
  background-color: #2579ec;
}

/* //Na classe que dá pras li dos cards diminuir um pouco o width... não sei se está em classe ou direto no código; */

.MuiGridListTile-root li {
  width: 15% !important;
  margin: 2px !important;
  padding: 25px !important;
}

/* //subtituir a cor #9124a3 para #3f51b5 */

.btn.btn-primary.active.focus,
.btn.btn-primary.active:focus,
.btn.btn-primary.active:hover,
.btn.btn-primary:active.focus,
.btn.btn-primary:active:focus,
.btn.btn-primary:active:hover,
.open > .btn.btn-primary.dropdown-toggle.focus,
.open > .btn.btn-primary.dropdown-toggle:focus,
.open > .btn.btn-primary.dropdown-toggle:hover,
.show > .btn.btn-primary.dropdown-toggle.focus,
.show > .btn.btn-primary.dropdown-toggle:focus,
.show > .btn.btn-primary.dropdown-toggle:hover {
  color: #fff;
  background-color: #3f51b5;
  border: 0;
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  border-radius: 20px !important;
}

/* CANCELAR RESERVA */

.razao_cancelamento {
  width: 100%;
  padding: 20px 0px 0px 75px !important;
}

.razao_title {
  text-align: left !important;
}

.alertCalendar {
  text-align: left;
  margin-top: 60px;
}

.navigation-bottom-cancel {
  margin-top: 40px;
  color: #333;
}

.navigation-bottom-cancel li {
  margin: 0 auto;
}

.cancel-content {
  padding: 40px;
}

.cancel-content h5 {
  text-align: center;
}

.cancel-content p {
  text-align: justify;
  color: rgb(85, 84, 84);
}

.cancel-content ul {
  text-align: left;
  color: #333;
}

.custom_icons {
  color: #999 !important;
  display: block;
  margin: 15px 15px 15px 20px;
}

.nav-pills .nav-item .nav-link.active .custom_icons {
  color: #fff !important;
}

.labelRadio {
  color: #333;
}

.home_icons {
  color: #111;
}

.home_icons:hover {
  color: #666;
}

.labelRadioBold span.MuiFormControlLabel-label {
  color: #000;
  font-weight: 500;
}

.fadeOut {
  opacity: 0;
  /* width:0;
    height:0; */
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
}

.fadeIn {
  opacity: 1;
  /* width:100px;
    height:100px; */
  transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
}

.recaptcha {
  width: 80%;
  margin-top: 0;
  margin-left: 22%;
  margin-bottom: 1em;
  margin-right: auto;
}

@media screen and (max-width: 1024px) {
  .recaptcha {
    margin-left: 21%;
  }
}

@media screen and (max-width: 480px) {
  .recaptcha {
    margin-left: 14%;
  }
}

@media screen and (max-width: 380px) {
  .recaptcha {
    margin-left: 5%;
  }
}

.card-container-queue {
  margin: 0px 20px 0px 20px;
  line-height: 1.2rem;
}

.containerLegend {
  margin-bottom: 120px;
}

.containerLegend h3 {
  text-align: center;
}

.legend {
  list-style: none;
  text-align: left;
  /* margin-left: -15px; */
}

.legend li {
  float: left;
  margin-right: 10px;
  width: 250px;
  margin-top: 10px;
}

.legend .span_legend {
  border: 1px solid #ccc;
  float: left;
  width: 16px;
  height: 16px;
  margin: 3px;
}

.legend .txt_legend {
  margin-left: 25px;
}

.legend .disponible {
  background-color: #2ecc71;
}

.legend .pronto {
  background-color: orange;
}

.legend .esgotadas {
  background-color: #ff0000;
}

.legend .proxima {
  background-color: #d0dfe7;
}

.selectedButton {
  font-size: 40px !important;
  padding: 5px;
  margin-right: 2px;
  cursor: pointer;
  /* box-shadow: #333 0px 1px 3px 1px; */
  border-radius: 20px;
}

.selectedButton:hover {
    box-shadow: inherit 0px 0px 2px;
    /* transform: rotate(360deg); */
    /* transition: all 0.25s ease-in-out; */
}
.selectedButton:active {
    box-shadow: inherit 1px 1px 6px;
}
.selectedButton .selectedButtonDisabled{
    box-shadow: none!important;
    background-color: #2ecc71;
}
/* .selectedButton:not(:hover) { */
  /* transform: rotate(180deg); */
  /* transition: all 0.25s ease-in-out; */
/* } */
.map-container{
  display: block;
  width: '100%';
  height: '100%';
  border-radius: 12px;
  border: 5px solid #FFF;
  outline: 5px solid #CCC;

}